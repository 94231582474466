<template>
  <div class="invest-project-pause-notice">
    <div class="wrapper">
      <p>
        <b>투자 서비스 중단 안내</b>
      </p>
      <ul class="font-sm">
        <li>
          <b>중단 일시</b>
          <div>{{ $definitions.limits.investPause.begin }} ~ {{ $definitions.limits.investPause.end }}</div>
        </li>
        <li>
          <b>중단 사유</b>
          <div>{{ $definitions.limits.investPause.reason }}</div>
        </li>
        <li>
          <b>중단 기능</b>
          <div>투자하기(청약 신청), 투자자 인증, 투자 취소</div>
        </li>
      </ul>
      <p></p>
      <div class="font-sm">서비스 이용에 불편을 드려 죄송합니다.</div>
    </div>
    <div class="action">
      <a class="btn btn-point btn-block font-sm" @click="close()">확인</a>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "modalInvestProjectPauseNotice";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    sample: Boolean
  },
  setup(props) {
    const component = new Component(async () => {
      !props.sample &&
      store.commit("tuneModal", {component, size: "sm"});
    });

    const modalParams = store.getters.modalParams(component);

    const close = () => {
      if (modalParams && modalParams.keep) {
        store.commit("closeModal", {name: component.name});
      } else {
        store.dispatch("moveBack");
      }
    };

    return {component, modalParams, close};
  }
});
</script>

<style lang="scss" scoped>
.invest-project-pause-notice {
  > .wrapper {
    background: #fff;
    padding: $px25;

    ul {
      padding-left: $px20;

      li {
        margin: $px10 0;
      }
    }
  }

  .action {
    .btn {
      padding: $px15;
      height: $px56;
      border-radius: 0;
    }
  }
}
</style>