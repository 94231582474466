<template>
  <div class="utm-pause">
    <div class="alert alert-secondary font-sm">
      <div class="mt-1">
        <span class="badge badge-primary">프로젝트 목록</span>
        <a href="/invest/list" target="_blank" class="ml-2">{{ siteUrl }}/invest/list</a>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 left">
        <div class="form-group">
          <label :for="`${component.name}Alert`">
            <span class="color-purple">*</span>
            <span> 안내 시작 일시</span>
          </label>
          <input type="text" class="form-control" :id="`${component.name}Alert`" placeholder="ex) 2023-04-16 00:00" maxlength="16" v-model="state.form.alert" @keyup.enter="submit()">
        </div>
        <div class="form-group">
          <label :for="`${component.name}Begin`">
            <span class="color-purple">*</span>
            <span> 중단 시작 일시</span>
          </label>
          <input type="text" class="form-control" :id="`${component.name}Begin`" placeholder="ex) 2023-04-16 09:00" maxlength="16" v-model="state.form.begin" @keyup.enter="submit()">
        </div>
        <div class="form-group">
          <label :for="`${component.name}End`">
            <span class="color-purple">*</span>
            <span> 중단 종료 일시</span>
          </label>
          <input type="text" class="form-control" :id="`${component.name}End`" placeholder="ex) 2023-04-16 18:00" maxlength="16" v-model="state.form.end" @keyup.enter="submit()">
        </div>
        <div class="form-group">
          <label :for="`${component.name}Reason`">
            <span class="color-purple">*</span>
            <span> 중단 사유</span>
          </label>
          <input type="text" class="form-control" :id="`${component.name}Reason`" placeholder="ex) 한국예탁결제원 시스템 전환 작업" maxlength="500" v-model="state.form.reason" @keyup.enter="submit()">
        </div>
        <div class="act">
          <button type="button" class="btn btn-primary btn-lg btn-block" @click="submit()">적용하기</button>
        </div>
      </div>
      <div class="col-12 col-lg-6 right pt-4 pt-lg-0">
        <div class="form-group">
          <label>안내창 미리보기</label>
        </div>
        <div class="wrapper">
          <InvestProjectPauseNotice :sample="true" :key="state.previewKey"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import InvestProjectPauseNotice from "@/modals/InvestProjectPauseNotice";
import http from "@/scripts/http";
import store from "@/scripts/store";
import definitions from "@/scripts/definitions";

function Component(initialize) {
  this.name = "pageAdminUtmGenerator";
  this.initialize = initialize;
}

export default defineComponent({
  components: {InvestProjectPauseNotice},
  mixins: [mixin],
  setup() {
    const component = new Component(async () => {
      http.get("/api/admin/invest/pause").then(({data}) => {
        if (data.body) {
          state.form = data.body;
        }
      });
    });

    const state = reactive({
      loaded: false,
      previewKey: 1,
      form: {
        alert: "",
        begin: "",
        end: "",
        reason: "",
      },
    });

    const siteUrl = window.location.origin;

    const submit = () => {
      const regex = /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]/;
      const tell = (id, message) => {
        document.getElementById(id)?.focus();
        return store.commit("setSwingMessage", message);
      };

      if (!state.form.alert || !state.form.alert.match(regex)) {
        return tell(`${component.name}Alert`, "안내 시작 일시 값을 확인해주세요.");
      } else if (!state.form.begin || !state.form.begin.match(regex)) {
        return tell(`${component.name}Begin`, "중단 시작 일시 값을 확인해주세요.");
      } else if (!state.form.end || !state.form.end.match(regex)) {
        return tell(`${component.name}End`, "중단 종료 일시 값을 확인해주세요.");
      } else if (!state.form.reason?.trim()) {
        return tell(`${component.name}Reason`, "중단 사유를 입력해주세요.");
      }

      http.put("/api/admin/invest/pause", state.form).then(() => {
        definitions.limits.investPause.begin = state.form.begin;
        definitions.limits.investPause.end = state.form.end;
        definitions.limits.investPause.reason = state.form.reason;
        state.previewKey += 1;
        store.commit("setSwingMessage", "입력하신 데이터를 적용하였습니다.");
      });
    };

    return {component, state, submit, siteUrl};
  }
});
</script>

<style lang="scss" scoped>
.utm-pause {
  .alert {
    background: $colorBackground;
    border: $px1 solid #eee;
    margin-bottom: $px15;
    padding-left: $px20;
    padding-right: $px20;

    a {
      color: inherit;
      font-weight: normal;
    }
  }

  > .row {
    padding-top: $px15;

    .form-group {
      > label {
        font-size: $px14;
      }
    }

    .left {
      .btn {
        border-radius: 0;
        height: $px56;
      }
    }

    .right {
      .form-group {
        margin-bottom: 0;
      }

      .wrapper {
        padding: $px43 $px60 $px60 $px60;
        border: $px1 solid $colorBorder;
        position: relative;

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        &::v-deep {
          .btn {
            color: #fff !important;
          }
        }
      }
    }
  }

  .act {
    padding-top: $px5;
  }

  @media(max-width: 767px) {
    > .row .right .wrapper {
      padding: 0 $px20 $px30 $px20;
    }
  }
}
</style>